import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCustom } from "apis/axiosCustom";

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (params: any) => {
    const res: any = await axiosCustom.get("product_short", { params });
    const resConverted = {
      ...res,
      results: res.results.map((product: any) => {
        return {
          ...product,
          checked: false,
        };
      }),
    };
    return resConverted;
  }
);

export const fetchProductTypes = createAsyncThunk(
  "products/fetchProductTypes",
  async () => {
    const res = await axiosCustom.get("product_type");
    return res;
  }
);

const productSlice = createSlice({
  name: "productList",
  initialState: {
    productLoading: false,
    productsInfo: {},
    productsErrorMessage: "",
    productTypesLoading: false,
    productTypesInfo: {},
    productTypesErrorMessage: "",
    activeProduct: null,
    previousPage: "",
  },
  reducers: {
    changeProductsInfo: (state: any, action) => {
      const { productsInfo } = state;
      const { results } = productsInfo;
      const convertCheckedResults = results.map((item: any) => {
        if (item.id === action.payload.productId) {
          return {
            ...item,
            checked: action.payload.checked,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        productsInfo: {
          ...productsInfo,
          results: convertCheckedResults,
        },
      };
    },
    clearAllSelectedProducts: (state: any) => {
      const { productsInfo } = state;
      const { results } = productsInfo;
      const convertCheckedResults = results.map((item: any) => {
        return {
          ...item,
          checked: false,
        };
      });
      return {
        ...state,
        productsInfo: {
          ...productsInfo,
          results: convertCheckedResults,
        },
      };
    },
    setActiveProduct: (state: any, action) => {
      return {
        ...state,
        activeProduct: action.payload,
      };
    },
    setPreviousPage: (state: any, action) => {
      return {
        ...state,
        previousPage: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state: any, action) => {
        state.productLoading = false;
        state.productsInfo = action.payload;
      })
      .addCase(fetchProducts.rejected, (state: any, action) => {
        state.productLoading = false;
        state.productsErrorMessage = action.payload;
      })

      .addCase(fetchProductTypes.pending, (state) => {
        state.productTypesLoading = true;
      })
      .addCase(fetchProductTypes.fulfilled, (state: any, action) => {
        state.productTypesLoading = false;
        state.productTypesInfo = action.payload;
      })
      .addCase(fetchProductTypes.rejected, (state: any, action) => {
        state.productTypesLoading = false;
        state.productTypesErrorMessage = action.payload;
      });
  },
});

export default productSlice;
