import axios from "axios";
import { ACCESS_TOKEN } from "constants/constants";
import { SIGN_IN_ROUTE } from "constants/routes";
import { toast } from "react-toastify";
import { history } from "utils/history";

export const axiosCustom = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosCustom.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  config.headers["Accept-Language"] = "en-US,en;q=0.5";
  return config;
});

axiosCustom.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    console.log("===>  err:", err);
    // const originalRequest = err.config;
    // if (err?.response?.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   try {
    //     const response = await axiosCustom.post("/token/refresh", {
    //       refresh: localStorage.getItem(REFRESH_TOKEN),
    //     });
    //     if (response.status === 200) {
    //       localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
    //       localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);
    //       return axiosCustom(originalRequest);
    //     }
    //   } catch (error) {
    //     history.push(SIGN_IN_ROUTE);
    //     toast.error("Refresh token failed");
    //   }
    // }
    switch (err?.response?.status) {
      case 401:
        toast.error(
          err.response?.data?.error_message || err.response?.data?.detail
        );
        history.push(SIGN_IN_ROUTE);
        return null;
      // case 403:
      //   toast.error(err.message);
      //   return null;
      default:
        toast.error(
          err.response?.data?.error_message || err.response?.data?.detail
        );
        return null;
    }
  }
);
