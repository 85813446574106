import { Redirect, Route } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants/constants";
import React from "react";
import MainLayout from "../components/MainLayout";
import { SIGN_IN_ROUTE } from "../constants/routes";
import { IRouteProps } from "./Router";

const PrivateRoute: React.FC<IRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem(ACCESS_TOKEN) ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN_ROUTE,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
