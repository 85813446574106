export const ENGLISH = {
  quotationList: "Quotation List",
  productList: "Product List",
  customerList: "Customer List",
  createCustomer: "Create Customer",
  searchCustomerName: "Search customer name...",
  customerName: "Customer Name",
  address: "Address",
  action: "Action",
  edit: "Edit",
  logOut: "Log Out",
  clickToSortAscending: "Click to sort ascending",
  clickToSortDescending: "Click to sort descending",
  clickToCancelSorting: "Click to cancel",
  customerInformation: "Customer Information",
  name: "Name",
  save: "Save",
  pleaseInputCustomerName: "Please input customer name!",
  pleaseInputCustomerAddress: "Please input customer address!",
  copied: "Copied",
  title: "Title",
  typeProductName: "Type product name",
  length: "Length",
  width: "Width",
  height: "Height",
  thickness: "Thickness",
  search: "Search",
  selectProductInTheListBelowToCreateQuotation:
    "Select product in the list below to create quotation",
  quotationRecordHistory: "Quotation record history",
  startDate: "Start date",
  endDate: "End date",
  createNewProduct: "Create new product",
  createNewRequest: "Create new request",
  selectStatus: "Select status",
  importExcel: "Import Excel",
  createdDate: "Created Date",
  approvedDate: "Approved Date",
  customer: "Customer",
  requestedBy: "Requested By",
  noOfProducts: "No. of products",
  status: "Status",
  requested: "Requested",
  done: "Done",
  selectACustomer: "Select a customer",
  notSpecified: "Not specified",
  createQuotation: "Create Quotation",
  productName: "Product Name",
  unitPrice: "Unit Price",
  moldFee: "Mold Fee",
  printingPlateFee: "Printing Plate Fee",
  productType: "Product Type",
  view: "View",
  products: "Products",
  product: "Product",
  selected: "selected",
  requestQuotation: "Request Quotation",
  selectedProducts: "Selected Products",
  removeAll: "Remove All",
  createNewQuotation: "Create New Quotation",
  customerOverview: "Customer Overview",
  thisIsRequiredField: "This is required field",
  productOverview: "Product Overview",
  selectAProductType: "Select A Product Type",
  innerLength: "Inner Length",
  innerWidth: "Inner Width",
  innerHeight: "Inner Height",
  sheetParameter: "Sheet Parameter",
  productPerSheet: "Product Per Sheet",
  areaPerSheet: "Area Per Sheet",
  layerCost: "Layer Cost",
  layer: "Layer",
  waveType: "Wave Type",
  paper: "Paper",
  price: "Price",
  totalPaperCost: "Total Paper Cost",
  loss: "Loss",
  paperCost: "Paper Cost",
  sheetProductionCost: "Sheet Production Cost",
  sheetMakingCost: "Sheet Making Cost",
  sheetLamination: "Sheet Lamination",
  totalSheetCost: "Total Sheet Cost",
  sheet: "Sheet",
  totalAfterPrintCost: "Total After Print Cost",
  noOfColor: "No. of Color",
  printCost: "Print Cost",
  totalProductionCost: "Total Production Cost",
  remark: "Remark",
  extraProductionSteps: "Extra Production Steps",
  uvSpotLamination: "UV Spot Lamination",
  foilStamp: "Foil Stamp",
  dieCut: "Die Cut",
  glue: "Glue",
  staple: "Staple",
  others: "Others",
  delivery: "Delivery",
  markup: "Markup",
  margin: "Margin",
  estimatedPrice: "Estimated Price",
  finalPrice: "Final Price",
  otherFees: "Other Fees",
  pageNotFound: "Page Not Found",
  thisRouterIsIncorrect: "This Router Is Incorrect",
  gotoLogin: "Go to Login",
  createCustomerSuccessfully: "Create Customer Successfully",
  updateCustomerSuccessfully: "Update customer successfully",
  welcomeBack: "Welcome Back!",
  password: "Password",
  pleaseInputYourPassword: "Please Input Your Password",
  signIn: "Sign In",
  duplicate: "Duplicate",
  remove: "Remove",
  paymentTerms: "Payment Terms",
  notes: "Notes",
  productQuotationList: "Product Quotation List",
  addProduct: "Add Product",
  submit: "Submit",
  cancel: "Cancel",
  saveDraft: "Save draft",
  createDate: "Create date",
  createBy: "Create by",
  attachments: "Attachments",
  uploadFile: "Upload file",
  productQuotation: "Product Quotation",
  extraTopPaper: "Extra Top Paper",
  topPaper: "Top Paper",
  wave: "Wave",
  middleLayer: "Middle Layer",
  bottomPaper: "Bottom Paper",
  printing: "Printing",
  createProductQuotationSuccessfully: "Create Product Quotation Successfully",
  updateProductQuotationSuccessfully: "Update Product Quotation Successfully",
  quotationRecord: "Quotation Record",
  saveChanges: "Save Changes",
};
