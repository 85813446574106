import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCustom } from "apis/axiosCustom";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (params: any) => {
    const res = await axiosCustom.get("customer", { params });
    return res;
  }
);

const customerSlice = createSlice({
  name: "customerList",
  initialState: { loadingCustomers: false, customers: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loadingCustomers = true;
      })
      .addCase(fetchCustomers.fulfilled, (state: any, action) => {
        state.customers = action.payload;
        state.loadingCustomers = false;
      });
  },
});

export default customerSlice;
