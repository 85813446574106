import { Image } from "antd";
import TrashInactiveSvg from "assets/icons/trash-inactive.svg";
import BoxActiveSvg from "assets/icons/box-active.svg";
import BoxInactiveSvg from "assets/icons/box-inactive.svg";
import LogoutSvg from "assets/icons/logout.svg";
import ShoppingCartActiveSvg from "assets/icons/shopping-cart-active.svg";
import ShoppingCartInactiveSvg from "assets/icons/shopping-cart-inactive.svg";
import UserLineSvg from "assets/icons/user-line.svg";
import UserLineActiveSvg from "assets/icons/user-line-active.svg";
import ShoppingFilledSvg from "assets/icons/shopping-cart-filled.svg";
import BoxFilledSvg from "assets/icons/box-filled.svg";
import BoxTickSvg from "assets/icons/box-tick.svg";
import UserActiveSvg from "assets/icons/user-active.svg";
import ArrowLeftSvg from "assets/icons/arrow-left.svg";
import CopySvg from "assets/icons/copy.svg";
import CopyInactiveSvg from "assets/icons/copy-inactive.svg";
import CodeSvg from "assets/icons/code.svg";
import BriefCaseSvg from "assets/icons/briefcase.svg";
import ColorSwatchSvg from "assets/icons/color-swatch.svg";
import LayerSvg from "assets/icons/layer.svg";
import TopLayerSvg from "assets/icons/top-layer-icon.svg";
import TopPaperSvg from "assets/icons/top-paper-icon.svg";
import MiddlePapperSvg from "assets/icons/middle-papper-icon.svg";
import BottomPaperSvg from "assets/icons/bottom-paper-icon.svg";
import WaveLayerSvg from "assets/icons/wave-layer-icon.svg";
import PlusCircleInactiveSvg from "assets/icons/plus-circle-inactive.svg";
import CheckCircleSvg from "assets/icons/CheckCircle.svg";
import CircleDashedSvg from "assets/icons/CircleDashed.svg";
import PencilAltSvg from "assets/icons/pencil-alt.svg";
import { toast } from "react-toastify";

export const TrashInactiveIcon = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <Image
    src={TrashInactiveSvg}
    preview={false}
    onClick={onClick}
    className="cursor-pointer"
  />
);

export const ArrowLeftIcon = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <Image
    src={ArrowLeftSvg}
    preview={false}
    onClick={onClick}
    className="cursor-pointer"
  />
);

export const CopyIcon = ({ copiedValue }: { copiedValue: string | number }) => {
  return (
    <Image
      src={CopySvg}
      preview={false}
      onClick={() => {
        navigator.clipboard.writeText(`${copiedValue}`);
        toast.info("Copied to clipboard", { autoClose: 2000 });
      }}
      className="cursor-pointer"
    />
  );
};

export const CopyInactiveIcon = ({
  copiedValue,
}: {
  copiedValue: string | number;
}) => (
  <Image
    src={CopyInactiveSvg}
    preview={false}
    onClick={() => {
      navigator.clipboard.writeText(`${copiedValue}`);
      toast.info("Copied to clipboard", { autoClose: 2000 });
    }}
    className="cursor-pointer"
  />
);

export const BoxActiveIcon = () => <Image src={BoxActiveSvg} preview={false} />;

export const BoxInactiveIcon = () => (
  <Image src={BoxInactiveSvg} preview={false} />
);

export const LogoutIcon = () => <Image src={LogoutSvg} preview={false} />;

export const ShoppingCartActiveIcon = () => (
  <Image src={ShoppingCartActiveSvg} preview={false} />
);

export const ShoppingCartInactiveIcon = () => (
  <Image src={ShoppingCartInactiveSvg} preview={false} />
);

export const UserLineIcon = () => <Image src={UserLineSvg} preview={false} />;

export const UserLineActiveIcon = () => (
  <Image src={UserLineActiveSvg} preview={false} />
);

export const ShoppingFilledIcon = () => (
  <Image src={ShoppingFilledSvg} preview={false} />
);

export const BoxFilledIcon = () => <Image src={BoxFilledSvg} preview={false} />;

export const BoxTickIcon = () => <Image src={BoxTickSvg} preview={false} />;

export const UserActiveIcon = () => (
  <Image src={UserActiveSvg} preview={false} />
);

export const CodeIcon = () => <Image src={CodeSvg} preview={false} />;

export const BriefCaseIcon = () => <Image src={BriefCaseSvg} preview={false} />;

export const ColorSwatchIcon = () => (
  <Image src={ColorSwatchSvg} preview={false} />
);

export const LayerIcon = () => <Image src={LayerSvg} preview={false} />;

export const TopLayerIcon = () => <Image src={TopLayerSvg} preview={false} />;

export const TopPaperIcon = () => <Image src={TopPaperSvg} preview={false} />;

export const MiddlePapperIcon = () => (
  <Image src={MiddlePapperSvg} preview={false} />
);

export const BottomPaperIcon = () => (
  <Image src={BottomPaperSvg} preview={false} />
);

export const WaveLayerIcon = () => <Image src={WaveLayerSvg} preview={false} />;

export const PlusCircleInactiveIcon = () => (
  <Image src={PlusCircleInactiveSvg} preview={false} />
);

export const CheckCircleIcon = () => (
  <Image src={CheckCircleSvg} preview={false} />
);

export const CircleDashedIcon = () => (
  <Image src={CircleDashedSvg} preview={false} />
);

export const PencilAltIcon = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <Image
    src={PencilAltSvg}
    preview={false}
    onClick={onClick}
    className="cursor-pointer"
  />
);
