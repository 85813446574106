import {
  CUSTOMERS_ROUTE,
  ERROR_404_ROUTE,
  PRODUCTS_ROUTE,
  QUOTATIONS_RECORD_ROUTE,
  QUOTATIONS_ROUTE,
  REQUEST_QUOTATIONS_ROUTE,
  SIGN_IN_ROUTE,
} from "../constants/routes";
import { IRouter } from "./Router";

const routes: IRouter[] = [
  {
    path: SIGN_IN_ROUTE,
    loader: () => import("../pages/login"),
    exact: true,
    type: "public",
  },
  {
    path: ERROR_404_ROUTE,
    loader: () => import("../pages/404notFound"),
    exact: true,
    type: "public",
  },
  {
    path: "/",
    loader: () => import("../pages/quotations"),
    exact: true,
    type: "private",
  },
  {
    path: QUOTATIONS_ROUTE,
    loader: () => import("../pages/quotations"),
    exact: true,
    type: "private",
  },
  {
    path: PRODUCTS_ROUTE,
    loader: () => import("../pages/products"),
    exact: true,
    type: "private",
  },
  {
    path: `${PRODUCTS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}`,
    loader: () => import("../pages/requestQuotation"),
    exact: true,
    type: "private",
  },
  {
    path: `${QUOTATIONS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}`,
    loader: () => import("../pages/requestQuotation"),
    exact: true,
    type: "private",
  },
  {
    path: `${PRODUCTS_ROUTE}${QUOTATIONS_RECORD_ROUTE}`,
    loader: () => import("../pages/requestQuotation"),
    exact: true,
    type: "private",
  },
  {
    path: CUSTOMERS_ROUTE,
    loader: () => import("../pages/customers"),
    exact: true,
    type: "private",
  },
];

export default routes;
