export const VIETNAMESE = {
  quotationList: "Danh sách báo giá",
  productList: "Danh sách sản phẩm",
  customerList: "Danh sách khách hàng",
  createCustomer: "Tạo khách hàng",
  searchCustomerName: "Tìm kiếm tên khách hàng...",
  customerName: "Tên khách hàng",
  address: "Địa chỉ",
  action: "Hành động",
  edit: "Sửa",
  logOut: "Đăng xuất",
  clickToSortAscending: "Nhấp chuột để sắp xếp theo chiều tăng",
  clickToSortDescending: "Nhấp chuột để sắp xếp theo chiều giảm",
  clickToCancel: "Nhấp chuột để huỷ sắp xếp",
  customerInformation: "Thông tin khách hàng",
  name: "Tên",
  save: "Lưu",
  pleaseInputCustomerName: "Thiếu tên khách hàng!",
  pleaseInputCustomerAddress: "Thiếu địa chỉ khách hàng!",
  copied: "Đã sao chép!",
  title: "Tên sản phầm",
  typeProductName: "Nhập tên sản phẩm",
  length: "Chiều dài",
  width: "Chiều rộng",
  height: "Chiều cao",
  thickness: "Độ dày",
  search: "Tìm kiếm",
  selectProductInTheListBelowToCreateQuotation:
    "Chọn sản phẩm trong danh sách bên dưới để tạo báo giá",
  quotationRecordHistory: "Lịch sử báo giá",
  startDate: "Ngày bắt đầu",
  endDate: "Ngày kết thúc",
  createNewProduct: "Tạo mới sản phẩm",
  createNewRequest: "Tạo mới yêu cầu",
  selectStatus: "Trạng thái",
  importExcel: "Nhập tệp excel",
  createdDate: "Ngày tạo",
  approvedDate: "Ngày duyệt",
  customer: "Khách hàng",
  requestedBy: "Yêu cầu bởi",
  noOfProducts: "Số lượng sản phẩm",
  status: "Trạng thái",
  requested: "Đã yêu cầu",
  done: "Đã hoàn thành",
  selectACustomer: "Chọn một khách hàng",
  notSpecified: "Chưa được chỉ định",
  createQuotation: "Tạo báo giá",
  productName: "Tên sản phẩm",
  unitPrice: "Đơn giá",
  moldFee: "Giá khuôn",
  printingPlateFee: "Phí in tấm",
  productType: "Loại sản phẩm",
  view: "Xem",
  products: "sản phẩm",
  product: "sản phẩm",
  selected: "đã chọn",
  requestQuotation: "Tạo báo giá",
  selectedProducts: "Sản phẩm đã chọn",
  removeAll: "Xoá tất cả",
  createNewQuotation: "Tạo mới báo giá",
  customerOverview: "Tổng quan về khách hàng",
  thisIsRequiredField: "Trường này là bắt buộc",
  productOverview: "Tổng quan sản phẩm",
  selectAProductType: "Chọn một loại sản phẩm",
  innerLength: "Chiều dài bên trong",
  innerWidth: "Chiều rộng bên trong",
  innerHeight: "Chiều cao bên trong",
  sheetParameter: "Tham số trang tính",
  productPerSheet: "Sản phẩm trên mỗi tấm",
  areaPerSheet: "Diện tích mỗi tấm",
  layerCost: "Chi phí một lớp",
  layer: "Lớp",
  waveType: "Kiểu sóng",
  paper: "Giấy",
  price: "Chi phí",
  totalPaperCost: "Tổng tiền giấy",
  loss: "Hao phí",
  paperCost: "Chi phí giấy",
  sheetProductionCost: "Chi phí sản xuất tấm",
  sheetMakingCost: "Chi phí làm tấm",
  sheetLamination: "Cán ép tấm",
  totalSheetCost: "Tổng chi phí tấm",
  sheet: "Tấm",
  totalAfterPrintCost: "Tổng chi phí sau in",
  noOfColor: "Số màu",
  printCost: "Chi phí in",
  totalProductionCost: "Tổng chi phí sản xuất",
  remark: "Nhận xét",
  extraProductionSteps: "Các bước sản xuất bổ sung",
  uvSpotLamination: "Cán tia UV",
  foilStamp: "Tem theo dõi",
  dieCut: "Cắt theo khuôn",
  glue: "Keo dán",
  staple: "Kẹp giấy",
  others: "Khác",
  delivery: "Vận chuyển",
  markup: "Đánh dấu",
  margin: "Lề",
  estimatedPrice: "Giá ước tính",
  finalPrice: "Giá niêm yết",
  otherFees: "Chi phí khác",
  pageNotFound: "Trang không tìm thấy",
  thisRouterIsIncorrect: "Đường dẫn không tồn tại",
  gotoLogin: "Đến trang đăng nhập",
  createCustomerSuccessfully: "Tạo khách hàng thành công",
  updateCustomerSuccessfully: "Cập nhật thông tin khách hàng thành công",
  welcomeBack: "Chào mừng bạn đã trở lại!",
  password: "Mật khẩu",
  pleaseInputYourPassword: "Thiếu thông tin mật khẩu",
  signIn: "Đăng nhập",
  duplicate: "Nhân bản",
  remove: "Xoá",
  paymentTerms: "Điều khoản thanh toán",
  notes: "Ghi chú",
  productQuotationList: "Danh sách báo giá sản phẩm",
  addProduct: "Thêm sản phẩm",
  submit: "Xác nhận",
  cancel: "Huỷ",
  saveDraft: "Lưu nháp",
  createDate: "Ngày tạo",
  createBy: "Tạo bởi",
  attachments: "Đính kèm",
  uploadFile: "Tải tệp lên",
  productQuotation: "Báo giá sản phẩm",
  extraTopPaper: "Lớp giấy mở rộng trên",
  topPaper: "Lớp giấy trên",
  wave: "Lớp",
  middleLayer: "Tầng giữa",
  bottomPaper: "Lớp giấy dưới",
  printing: "In ấn",
  createProductQuotationSuccessfully: "Tạo báo giá sản phẩm thành công",
  updateProductQuotationSuccessfully: "Cập nhật báo giá sản phẩm thành công",
  quotationRecord: "Bản ghi báo giá",
};
