import { createSlice } from "@reduxjs/toolkit";

const mainLayoutSlice = createSlice({
  name: "mainLayout",
  initialState: {
    collapsed: false,
  },
  reducers: {
    toggleCollapse: (state) => {
      state.collapsed = !state.collapsed;
    },
  },
});

export default mainLayoutSlice;
