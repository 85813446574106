import FallbackScreen from "./FallbackScreen";
import loadable, {
  DefaultComponent,
  LoadableComponent,
} from "@loadable/component";
import React from "react";
import PrivateRoute from "./PrivateRouter";
import { Switch, Route, Redirect } from "react-router-dom";
import { ERROR_404_ROUTE } from "constants/routes";

export interface IRouter {
  path: string;
  loader: () => Promise<DefaultComponent<any>>;
  exact: boolean;
  type: "public" | "private";
}

interface IProps {
  routes: IRouter[];
}

export interface IRouteProps {
  path: string;
  exact: boolean;
  component: LoadableComponent<any>;
}

const Router: React.FC<IProps> = ({ routes }) => {
  const routeSelector = (router: IRouter, key: number) => {
    const { path, exact, loader, type } = router;
    const props: IRouteProps = {
      path,
      exact,
      component: loadable(loader, { fallback: <FallbackScreen /> }),
    };
    if (type === "private") {
      return <PrivateRoute key={key} {...props} />;
    }
    return <Route key={key} {...props} />;
  };

  return (
    <Switch>
      {routes.map((router, index) => routeSelector(router, index))}
      <Redirect to={ERROR_404_ROUTE} />
    </Switch>
  );
};

export default Router;
