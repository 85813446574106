import type { MenuProps } from "antd";
import { Button, Dropdown, Image, Layout, Menu, Select } from "antd";
import Logo from "assets/imgs/logo.png";
import { ACCESS_TOKEN, LANG_CODE, REFRESH_TOKEN } from "constants/constants";
import {
  CUSTOMERS_ROUTE,
  PRODUCTS_ROUTE,
  QUOTATIONS_RECORD_ROUTE,
  QUOTATIONS_ROUTE,
  REQUEST_QUOTATIONS_ROUTE,
  ROOT_ROUTE,
  SIGN_IN_ROUTE,
} from "constants/routes";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DecodeInfoType, ProductType, UserInfo } from "types";
import { history } from "utils/history";
import { getFirstCharacterOfWords } from "utils/commonFunctions";
import { jwtDecode } from "jwt-decode";
import ProductCard from "pages/products/ProductCard";
import { axiosCustom } from "apis/axiosCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, mainLayoutSelector, productsSelector } from "app/store";
import mainLayoutSlice from "../app/mainLayoutSlice";
import "./MainLayout.scss";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import {
  ArrowLeftIcon,
  BoxActiveIcon,
  BoxFilledIcon,
  BoxInactiveIcon,
  BoxTickIcon,
  LogoutIcon,
  ShoppingCartActiveIcon,
  ShoppingCartInactiveIcon,
  ShoppingFilledIcon,
  UserActiveIcon,
  UserLineActiveIcon,
  UserLineIcon,
} from "./Icons";
import productSlice from "app/productSlice";
import { toast } from "react-toastify";
import { fetchQuotationById } from "app/quotationSlice";

type MenuItem = Required<MenuProps>["items"][number];

const layoutStyle = {
  height: "100vh",
};

const { Header, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  height: 56,
  backgroundColor: "white",
  borderBottom: "1px solid #D3D3D3",
  padding: "0px",
  lineHeight: "0px",
};

const contentStyle: React.CSSProperties = {
  backgroundColor: "#EAEAEA",
};

const MainLayout = ({ children }: { children: React.ReactElement<any> }) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { i18n, t } = useTranslation();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "Default name",
    email: "Default email",
  });
  const [isShowFullSider, setIsShowFullSider] = useState<boolean>(true);
  const [productsSelected, setProductsSelected] = useState([]);

  const { collapsed } = useSelector(mainLayoutSelector);
  const productReducer = useSelector(productsSelector);
  const { productsInfo } = productReducer;

  const items: MenuItem[] = [
    {
      key: QUOTATIONS_ROUTE,
      icon: (
        <span className="anticon">
          {location.pathname === QUOTATIONS_ROUTE ||
          location.pathname === ROOT_ROUTE ||
          location.pathname ===
            `${QUOTATIONS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}` ? (
            <ShoppingCartActiveIcon />
          ) : (
            <ShoppingCartInactiveIcon />
          )}
        </span>
      ),
      label: t("quotationList"),
    },
    {
      key: PRODUCTS_ROUTE,
      icon: (
        <span className="anticon">
          {location.pathname === PRODUCTS_ROUTE ||
          location.pathname ===
            `${PRODUCTS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}` ||
          location.pathname ===
            `${PRODUCTS_ROUTE}${QUOTATIONS_RECORD_ROUTE}` ? (
            <BoxActiveIcon />
          ) : (
            <BoxInactiveIcon />
          )}
        </span>
      ),
      label: t("productList"),
    },
    {
      key: CUSTOMERS_ROUTE,
      icon: (
        <span className="anticon">
          {location.pathname === CUSTOMERS_ROUTE ? (
            <UserLineActiveIcon />
          ) : (
            <UserLineIcon />
          )}
        </span>
      ),
      label: t("customerList"),
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    history.push(e.key);
  };

  const onClickLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    history.push(SIGN_IN_ROUTE);
  };

  const onClickLogo = () => {
    dispatch(mainLayoutSlice.actions.toggleCollapse());
  };

  const onClickRemoveAll = () => {
    dispatch(productSlice.actions.clearAllSelectedProducts());
  };

  const onChangeLang = (lang_code: string) => {
    i18n.changeLanguage(lang_code);
    localStorage.setItem(LANG_CODE, lang_code);
  };

  const getMenuSectedByRoute = () => {
    switch (location.pathname) {
      case ROOT_ROUTE:
        return [QUOTATIONS_ROUTE];
      case `${QUOTATIONS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}`:
        return [QUOTATIONS_ROUTE];
      case `${PRODUCTS_ROUTE}${QUOTATIONS_RECORD_ROUTE}`:
      case `${PRODUCTS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}`:
        return [PRODUCTS_ROUTE];
      default:
        return [location.pathname];
    }
  };

  const onClickRequestQuotation = async () => {
    const selectedProducts: any = productsInfo?.results?.filter(
      (product: any) => product.checked
    );
    let isSameCustomer = true;
    if (selectedProducts.length >= 2) {
      const firstCustomerId = selectedProducts[0].customer.id;
      selectedProducts.forEach((product: any) => {
        if (product.customer.id === firstCustomerId) {
          isSameCustomer = false;
        }
      });
    }
    if (isSameCustomer) {
      const res: any = await axiosCustom.post("/quotation/", {
        status: 1,
        quotation_record: selectedProducts?.map((product: any) => {
          return {
            product_id: product.id,
            is_empty: true,
          };
        }),
      });
      if (res) {
        dispatch(fetchQuotationById(res.id));
        dispatch(productSlice.actions.setPreviousPage("products"));
        history.push(`${PRODUCTS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}`);
      }
    } else {
      isSameCustomer = true;
      toast.error("Selected products is not same customers");
    }
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem(LANG_CODE) || "en");
    const decodedInfo: DecodeInfoType = jwtDecode(
      localStorage.getItem(ACCESS_TOKEN) || ""
    );
    if (decodedInfo.exp < Date.now() / 1000) {
      axiosCustom
        .post("token/refresh", {
          refresh: localStorage.getItem(REFRESH_TOKEN),
        })
        .then((res: any) => {
          res.access && localStorage.setItem(ACCESS_TOKEN, res.access);
          res.refresh && localStorage.setItem(REFRESH_TOKEN, res.refresh);
        });
    }
    setUserInfo({ email: decodedInfo.email, name: decodedInfo.username });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (collapsed) {
      setIsShowFullSider(false);
    } else {
      setTimeout(() => {
        setIsShowFullSider(true);
      }, 100);
    }
  }, [collapsed]);

  useEffect(() => {
    if (productsInfo?.results) {
      setProductsSelected(
        productsInfo.results.filter(
          (product: { checked: boolean }) => product.checked
        )
      );
    } else {
      setProductsSelected([]);
    }
  }, [productsInfo]);

  return (
    <Layout style={layoutStyle}>
      <Sider
        collapsed={collapsed}
        theme="light"
        width="239px"
        collapsedWidth="72px"
        className="right-menu-container"
      >
        <div className="top-content">
          <div className="main-logo-wrapper" onClick={onClickLogo}>
            <Image
              width={36}
              className="main-logo"
              src={Logo}
              alt="Picture of the author"
              preview={false}
            />
            {isShowFullSider && (
              <div className="company-name">An Thinh Co, LTD</div>
            )}
          </div>
          <div className="user-info">
            <div className="name-logo">
              {getFirstCharacterOfWords(userInfo.name || "Wedo AI")}
            </div>
            {isShowFullSider && (
              <div className="name-info">
                <div className="name">{userInfo.name || "Wedo AI"}</div>
                <div className="email" title={userInfo.email}>
                  {userInfo.email || "basicemail.com"}
                </div>
              </div>
            )}
          </div>
          <Menu
            className="menus"
            onClick={onClick}
            selectedKeys={getMenuSectedByRoute()}
            mode="inline"
            items={items}
          />
        </div>
        <div>
          <div className="d-flex content-center m-bt-12">
            {isShowFullSider && (
              <GlobalOutlined
                style={{ fontSize: "26px", color: "#fdb913", marginRight: 12 }}
              />
            )}
            <Select
              style={{ width: isShowFullSider ? 120 : 61 }}
              value={
                localStorage.getItem(LANG_CODE) === "en"
                  ? isShowFullSider
                    ? "English"
                    : "EN"
                  : isShowFullSider
                  ? "Vietnamese"
                  : "VI"
              }
              onChange={onChangeLang}
              options={[
                { value: "en", label: isShowFullSider ? "English" : "EN" },
                { value: "vi", label: isShowFullSider ? "Vietnamese" : "VI" },
              ]}
            />
          </div>
          <div className="bottom-content" onClick={onClickLogout}>
            <LogoutIcon />
            {isShowFullSider && <div className="m-l-12">{t("logOut")}</div>}
          </div>
        </div>
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          {(location.pathname === QUOTATIONS_ROUTE ||
            location.pathname === ROOT_ROUTE) && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <ShoppingFilledIcon />
                <div className="m-l-12 header-title">{t("quotationList")}</div>
              </div>
            </div>
          )}
          {location.pathname === PRODUCTS_ROUTE && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <BoxFilledIcon />
                <div className="m-l-12 header-title">{t("productList")}</div>
              </div>
              <div className="header-right-content">
                {productsSelected.length > 0 ? (
                  <>
                    <Dropdown
                      trigger={["click"]}
                      dropdownRender={() => (
                        <div className="selected-product-wrapper">
                          <div className="p-12 font-weight-500 selected-count">
                            {t("selectedProducts")} ({productsSelected.length})
                          </div>
                          <div className="selected-products-content">
                            {productsSelected.map(
                              (product: ProductType, index: number) => (
                                <ProductCard
                                  key={index}
                                  product={product}
                                  isViewMode={true}
                                />
                              )
                            )}
                          </div>
                          <div
                            className="p-12 d-flex content-center btn-remove-all cursor-pointer"
                            onClick={onClickRemoveAll}
                          >
                            {t("removeAll")}
                          </div>
                        </div>
                      )}
                      placement="bottomRight"
                    >
                      <Button className="btn-view-selected-prod">
                        {t("view")} {productsSelected.length}{" "}
                        {productsSelected.length > 1
                          ? t("products")
                          : t("product")}{" "}
                        {t("selected")}
                      </Button>
                    </Dropdown>
                    <Button
                      className="anthinh-btn-primary m-l-10"
                      onClick={onClickRequestQuotation}
                    >
                      {t("requestQuotation")}
                    </Button>
                  </>
                ) : (
                  <>
                    <BoxTickIcon />
                    <div className="m-l-4 header-description">
                      {t("selectProductInTheListBelowToCreateQuotation")}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {location.pathname === CUSTOMERS_ROUTE && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <UserActiveIcon />
                <div className="m-l-12 header-title">{t("customerList")}</div>
              </div>
            </div>
          )}
          {location.pathname ===
            `${PRODUCTS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}` && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <ArrowLeftIcon onClick={() => history.goBack()} />
                <div className="m-l-12 header-title">
                  {t("createNewQuotation")}
                </div>
              </div>
            </div>
          )}
          {location.pathname ===
            `${PRODUCTS_ROUTE}${QUOTATIONS_RECORD_ROUTE}` && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <ArrowLeftIcon onClick={() => history.goBack()} />
                <div className="m-l-12 header-title">
                  {t("quotationRecord")}
                </div>
              </div>
            </div>
          )}
          {location.pathname ===
            `${QUOTATIONS_ROUTE}${REQUEST_QUOTATIONS_ROUTE}` && (
            <div className="header-wrapper">
              <div className="header-left-content">
                <ArrowLeftIcon onClick={() => history.push(QUOTATIONS_ROUTE)} />
                <div className="m-l-12 header-title">
                  {t("createNewQuotation")}
                </div>
              </div>
            </div>
          )}
        </Header>
        <Content style={contentStyle}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
