import { ConnectedRouter } from "connected-react-router";
import routes from "./routes";
import Router from "./routes/Router";
import { history } from "utils/history";
import { Provider } from "react-redux";
import { store } from "app/store";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router routes={routes} />
      </ConnectedRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  );
}

export default App;
