import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCustom } from "apis/axiosCustom";

export const fetchQuotations = createAsyncThunk(
  "quotations/fetchQuotations",
  async (params: any) => {
    const res: any = await axiosCustom.get("product_quotation/", { params });
    return res;
  }
);

export const fetchQuotationById = createAsyncThunk(
  "fetchQuotationById",
  async (id: number) => {
    const res: any = await axiosCustom.get(`quotation/${id}`);
    return res;
  }
);

const quotationSlice = createSlice({
  name: "quotationList",
  initialState: {
    quotationLoading: false,
    quotationsInfo: {},
    quotationsErrorMessage: "",
    quotationDetailsLoading: false,
    quotationDetails: {},
    quotationDetailsErrorMessage: "",
  }, // Add the initialState property
  reducers: {}, // Add the reducers property
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotations.pending, (state) => {
        state.quotationLoading = true;
      })
      .addCase(fetchQuotations.fulfilled, (state, action) => {
        state.quotationLoading = false;
        state.quotationsInfo = action.payload;
        state.quotationsErrorMessage = "";
      })
      .addCase(fetchQuotations.rejected, (state, action: any) => {
        state.quotationLoading = false;
        state.quotationsInfo = {};
        state.quotationsErrorMessage = action.payload;
      })

      .addCase(fetchQuotationById.pending, (state) => {
        state.quotationDetailsLoading = true;
      })
      .addCase(fetchQuotationById.fulfilled, (state, action) => {
        state.quotationDetailsLoading = false;
        state.quotationDetails = action.payload;
        state.quotationDetailsErrorMessage = "";
      })
      .addCase(fetchQuotationById.rejected, (state, action: any) => {
        state.quotationDetailsLoading = false;
        state.quotationDetails = {};
        state.quotationDetailsErrorMessage = action.payload;
      });
  },
});

export default quotationSlice;
