export const getFirstCharacterOfWords = (str: string) =>
  str
    .split(" ") // Split the string into an array of words
    .map((word: string) => word.charAt(0)) // Map over each word and get its first character
    .join(""); // Join the characters together

export const getLastWord = (str: string) => {
  const parts = str.split("/");
  return parts[parts.length - 1];
};

export const isNullOrUndefined = (param: string) => {
  return param === null || param === undefined;
};

export const filterOptionByLabel = (
  input: string,
  option?: { label: string; value: number }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const convertWaveValueToWaveId = (value: number) => {
  switch (value) {
    case 1.6:
      return 1;
    case 1.5:
      return 2;
    case 1.45:
      return 3;
    case 1.4:
      return 4;
    default:
      return 1;
  }
};
