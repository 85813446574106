import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mainLayoutSlice from "app/mainLayoutSlice";
import { connectRouter } from "connected-react-router";
import customerSlice from "app/customerSlice";
import productSlice from "app/productSlice";
import { history } from "utils/history";
import quotationSlice from "./quotationSlice";

const rootReducer = combineReducers({
  router: connectRouter(history),
  customer: customerSlice.reducer,
  layout: mainLayoutSlice.reducer,
  product: productSlice.reducer,
  quotation: quotationSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export const customersSelector = (state: {
  customer: {
    loadingCustomers: boolean;
    customers: { results: [] };
  };
}) => state.customer;

export const productsSelector = (state: {
  product: {
    productLoading: boolean;
    productsInfo: { results: []; count: number };
    productsErrorMessage: string;
    productTypesLoading: boolean;
    productTypesInfo: { results: [] };
    productTypesErrorMessage: string;
    activeProduct: any;
    previousPage: string;
  };
}) => state.product;

export const quotationSelector = (state: {
  quotation: {
    quotationLoading: boolean;
    quotationsInfo: { count: number; results: [] };
    quotationsErrorMessage: string;
    quotationDetailsLoading: boolean;
    quotationDetails: any;
    quotationDetailsErrorMessage: string;
  };
}) => state.quotation;

export const mainLayoutSelector = (state: { layout: { collapsed: boolean } }) =>
  state.layout;
